/**
 * Mapping from numeric key code to key name. If the value is an array, the first element is the
 * primary key name, and the second element is the key name when shift is pressed.
 */
export const codes: { [code: string]: string | string[] } = {
  // ----------------------------------------
  // By Code
  // ----------------------------------------
  3: 'Cancel',
  6: 'Help',
  8: 'Backspace',
  9: 'Tab',
  12: 'Clear',
  13: 'Enter',
  16: 'Shift',
  17: 'Control',
  18: 'Alt',
  19: 'Pause',
  20: 'CapsLock',
  27: 'Escape',
  28: 'Convert',
  29: 'NonConvert',
  30: 'Accept',
  31: 'ModeChange',
  32: ' ',
  33: 'PageUp',
  34: 'PageDown',
  35: 'End',
  36: 'Home',
  37: 'ArrowLeft',
  38: 'ArrowUp',
  39: 'ArrowRight',
  40: 'ArrowDown',
  41: 'Select',
  42: 'Print',
  43: 'Execute',
  44: 'PrintScreen',
  45: 'Insert',
  46: 'Delete',
  48: ['0', ')'],
  49: ['1', '!'],
  50: ['2', '@'],
  51: ['3', '#'],
  52: ['4', '$'],
  53: ['5', '%'],
  54: ['6', '^'],
  55: ['7', '&'],
  56: ['8', '*'],
  57: ['9', '('],
  65: ['a', 'A'],
  66: ['b', 'B'],
  67: ['c', 'C'],
  68: ['d', 'D'],
  69: ['e', 'E'],
  70: ['f', 'F'],
  71: ['g', 'G'],
  72: ['h', 'H'],
  73: ['i', 'I'],
  74: ['j', 'J'],
  75: ['k', 'K'],
  76: ['l', 'L'],
  77: ['m', 'M'],
  78: ['n', 'N'],
  79: ['o', 'O'],
  80: ['p', 'P'],
  81: ['q', 'Q'],
  82: ['r', 'R'],
  83: ['s', 'S'],
  84: ['t', 'T'],
  85: ['u', 'U'],
  86: ['v', 'V'],
  87: ['w', 'W'],
  88: ['x', 'X'],
  89: ['y', 'Y'],
  90: ['z', 'Z'],
  91: 'OS',
  93: 'ContextMenu',
  112: 'F1',
  113: 'F2',
  114: 'F3',
  115: 'F4',
  116: 'F5',
  117: 'F6',
  118: 'F7',
  119: 'F8',
  120: 'F9',
  121: 'F10',
  122: 'F11',
  123: 'F12',
  124: 'F13',
  125: 'F14',
  126: 'F15',
  127: 'F16',
  128: 'F17',
  129: 'F18',
  130: 'F19',
  131: 'F20',
  132: 'F21',
  133: 'F22',
  134: 'F23',
  135: 'F24',
  144: 'NumLock',
  145: 'ScrollLock',
  181: 'VolumeMute',
  182: 'VolumeDown',
  183: 'VolumeUp',
  186: [';', ':'],
  187: ['=', '+'],
  188: [',', '<'],
  189: ['-', '_'],
  190: ['.', '>'],
  191: ['/', '?'],
  192: ['`', '~'],
  219: ['[', '{'],
  220: ['\\', '|'],
  221: [']', '}'],
  222: ["'", '"'],
  224: 'Meta',
  225: 'AltGraph',
  246: 'Attn',
  247: 'CrSel',
  248: 'ExSel',
  249: 'EraseEof',
  250: 'Play',
  251: 'ZoomOut',
};
