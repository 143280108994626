import { colors } from '../teams/siteVariables';
import { accessibleCyan } from './colors';

export * from './colors';

//
// BORDER STYLES
//
export const focusInnerBorderColor = colors.black;
export const focusOuterBorderColor = accessibleCyan;

//
// SEMANTIC ASSIGNMENTS
//
export const bodyBackground = colors.black;
export const bodyColor = colors.white;
