import { colors } from '../teams/siteVariables';

export { colorScheme, categoryColorScheme } from './colors';

//
// BORDER STYLES
//
export const focusInnerBorderColor = colors.black;
export const focusOuterBorderColor = colors.white;

//
// SHADOW LEVELS
//
export const shadowLevel1 = '0 .2rem .4rem -.075rem rgba(0, 0, 0, .25)';
export const shadowLevel2 = '0 .4rem 0.7rem -0.1rem rgba(0, 0, 0, .25)';
export const shadowLevel3 = '0 .8rem 1rem -0.2rem rgba(0, 0, 0, .25)';
export const shadowLevel4 = '0 .6rem 1.8rem -0.4rem rgba(0, 0, 0, .25)';

//
// SEMANTIC ASSIGNMENTS
//
export const bodyBackground = colors.grey[650];
export const bodyColor = colors.white;
