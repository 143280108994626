import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const DownloadIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" viewBox="2 2 16 16" className={classes.svg}>
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M15.5 16.9989C15.7761 16.9989 16 17.2227 16 17.4989C16 17.7443 15.8231 17.9485 15.5899 17.9908L15.5 17.9989H4.5C4.22386 17.9989 4 17.775 4 17.4989C4 17.2534 4.17688 17.0493 4.41012 17.0069L4.5 16.9989H15.5ZM10.0001 2.0011C10.2456 2.0011 10.4497 2.1781 10.492 2.41137L10.5 2.50124L10.496 14.2951L14.1414 10.6468C14.3148 10.473 14.5842 10.4535 14.7792 10.5883L14.8485 10.6461C15.0222 10.8195 15.0418 11.0889 14.907 11.2839L14.8492 11.3532L10.3574 15.8532C10.285 15.9259 10.1957 15.9715 10.1021 15.9902L9.99608 16C9.83511 16 9.69192 15.9239 9.60051 15.8057L5.14386 11.3538C4.94846 11.1587 4.94823 10.8421 5.14336 10.6467C5.3168 10.473 5.58621 10.4535 5.78117 10.5884L5.85046 10.6462L9.496 14.2871L9.5 2.50095C9.50008 2.22481 9.724 2.0011 10.0001 2.0011Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M15.2444 16.4976C15.6586 16.4976 15.9944 16.8334 15.9944 17.2476C15.9944 17.6273 15.7123 17.9411 15.3462 17.9907L15.2444 17.9976H4.74976C4.33554 17.9976 3.99976 17.6618 3.99976 17.2476C3.99976 16.8679 4.28191 16.5541 4.64799 16.5044L4.74976 16.4976H15.2444ZM10.0034 1.99939C10.3831 1.99939 10.6969 2.28154 10.7465 2.64762L10.7534 2.74939L10.7528 12.9424L13.719 9.97181C13.985 9.7053 14.4016 9.68071 14.6955 9.89829L14.7796 9.97083C15.0461 10.2369 15.0707 10.6535 14.8532 10.9473L14.7806 11.0315L10.5378 15.2821L10.4671 15.3446L10.3762 15.4032L10.3397 15.4244L10.2486 15.4623L10.1281 15.4924L10.0588 15.5003L9.99876 15.5023C9.94864 15.5023 9.89776 15.4968 9.84804 15.4862L9.76716 15.4631C9.67115 15.4321 9.58404 15.3818 9.50989 15.3171L5.22032 11.0324C4.92721 10.7397 4.92688 10.2648 5.21956 9.9717C5.48564 9.70524 5.90228 9.68074 6.19605 9.89838L6.28022 9.97094L9.25276 12.9384L9.25338 2.74939C9.25338 2.33518 9.58917 1.99939 10.0034 1.99939Z"
      />
    </svg>
  ),
  displayName: 'DownloadIcon',
});
